import {BillingModeTypes} from "../app/shared/interfaces/billing/billing-mode.types";

export const environment = {
  firebase: {
    projectId: 'call-center-d08cb',
    appId: '1:1030804807834:web:8b02ec1205399fef1e3f23',
    storageBucket: 'call-center-d08cb.appspot.com',
    apiKey: 'AIzaSyAiykT3uqzE0hTJa2waVB0FwTTcJk84rOk',
    authDomain: 'call-center-d08cb.firebaseapp.com',
    messagingSenderId: '1030804807834',
  },
  production: false,
  server: 'https://us-central1-call-center-d08cb.cloudfunctions.net/',
  v2Url: 'https://core-dev.datamorf.io/v2/',
  core: 'http://localhost:6060/',
  paddleVendorId: 14265,
  getBetaDiscount: (billingMode: BillingModeTypes) => {
    switch (billingMode) {
      case "Annually":
        return 'dsc_01h969wkc072jarwwbtmaeh7fq'
      case "Monthly":
        return 'dsc_01h969w8hs1sqztf02gw3qmjdc'
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
